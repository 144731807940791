import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const TeamsIntro = () => {
  return (
    <OnboardingIntro
      // @TODO: change
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        category="teams"
        title="Evaluate and Adjust Your Teams and Departments"
        text="Explore and assess your established team and department structure; a crucial foundation for unlocking future value through recruitment and performance features. Add some examples"
        items={[
          {
            key: 'assign',
            title: 'Assign owners',
            icon: 'Services',
            text: "Every team and department requires an owner—picture this owner as the team's manager. It is imperative to assign owners for both teams and departments, as they will hold authority in overseeing goals, talents, and performance reviews.",
          },
          {
            key: 'enable',
            title: 'Enable departments if you need',
            icon: 'RepairTool',
            text: 'Explore the advantage of organizing your teams within departments; this can be beneficial for various reasons.',
          },
        ]}
      />
    </OnboardingIntro>
  )
}
