import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const EmployeeRecordsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Employees.mp4"
      videoPosterUrl="/intros/Intro%20-%20Employees.png"
    >
      <OnboardingIntroTips
        category="employeeRecords"
        title="Unify and customise all employee records"
        items={[
          {
            key: 'records',
            title: 'Employee records',
            icon: 'Profile',
            text: 'All your employee information can be stored in one place, we bring many fields for you but you are always free to add more employee fields to store all your information.',
          },
          {
            key: 'lifecycle',
            title: 'Employee lifecycle',
            icon: 'Rocket',
            text: 'Onboard employees into your product, providing a great experience from the beginning. When offboarding make sure you are compliant.',
          },
          {
            key: 'compensation',
            title: 'Contract & compensation',
            icon: 'Document',
            text: 'All your employment contract data is stored as a digital contract, with compensation, seniority and more data.',
          },
        ]}
      />
    </OnboardingIntro>
  )
}
