import React from 'react'
import { Box, HStack, Icon, IconName, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklistV2'

type Item = {
  icon: IconName | { url: string }
  title: string
  text: string
  key: string
}

type Props = {
  title: string
  text?: string
  items: Item[]
  category: OnboardingCheckpointCategory
  // for events tracking as it could be multiple components on the page
  eventSuffix?: string
}

export const OnboardingIntroTips = ({
  title,
  text,
  items,
  category,
  eventSuffix = '',
}: Props) => {
  return (
    <Widget p="s-16">
      <Text variant="h5" display="block" mb="s-16">
        <FormattedMessage
          id={`onboardingV2.${category}.intro.tips.title${
            eventSuffix ? `-${eventSuffix}` : ''
          }`}
          defaultMessage={title}
        />
      </Text>
      {text && (
        <Text variant="caption" display="block">
          <FormattedMessage
            id={`onboardingV2.${category}.intro.tips.text${
              eventSuffix ? `-${eventSuffix}` : ''
            }`}
            defaultMessage={text}
          />
        </Text>
      )}
      <VStack gap="s-16" mt="s-24">
        {items.map((item, i) => (
          <HStack gap="s-16" key={i}>
            <Icon
              size={23}
              src={typeof item.icon === 'object' ? item.icon.url : undefined}
              variant={typeof item.icon === 'object' ? 'image' : undefined}
              name={typeof item.icon === 'string' ? item.icon : undefined}
            />
            <Box>
              <Text variant="primary">
                <FormattedMessage
                  id={`onboardingV2.${category}.intro.tips.title.${item.key}`}
                  defaultMessage={item.title}
                />
              </Text>
              <Text color={Token.color.greyTone50} display="block" mt="s-8">
                <FormattedMessage
                  id={`onboardingV2.${category}.intro.tips.text.${item.key}`}
                  defaultMessage={item.text}
                  values={{
                    br: <br />,
                  }}
                />
              </Text>
            </Box>
          </HStack>
        ))}
      </VStack>
    </Widget>
  )
}
