import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklistV2'

const List = styled.ul`
  font-weight: 700;
  margin-left: 20px;
  padding: 16px 0;
`

const ListItem = styled.li``

type Item = {
  id: string
  msg: string
}

type Props = {
  items: Item[]
  category: OnboardingCheckpointCategory
}

export const OnboardingIntroList = ({ items, category }: Props) => {
  return (
    <List>
      {items.map(item => (
        <ListItem key={item.id}>
          <FormattedMessage
            id={`onboardingV2.${category}.intro.list.${item.id}`}
            defaultMessage={item.msg}
          />
        </ListItem>
      ))}
    </List>
  )
}
