import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const TimeManagementIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Time%20Off.mov"
      videoPosterUrl="/intros/Intro%20-%20Time%20Off.png"
    >
      <OnboardingIntroTips
        category="timeManagement"
        title="Create time off policies that match your needs"
        text="Customise the policies to fit the needs of your company or your legal requirements, then decide who gets assigned each of these policies."
        items={[
          {
            key: 'create',
            title: 'Create time off policies',
            icon: 'Document',
            text: 'Create policies which define how much leave an employee earns, who needs to approve their time off and more.',
          },
          {
            key: 'assign',
            title: 'Assign policies automatically or manually',
            icon: 'People',
            text: 'After a policy is created, decide who this policy is assigned to by using filters or groups. For example assign an Annual leave policy to everyone in the UK who is a senior. From there on out, anyone who fits this criteria will be assigned the policy.',
          },
        ]}
      />
    </OnboardingIntro>
  )
}
