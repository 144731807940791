import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'

export const GoalsIntro = () => {
  return (
    <OnboardingIntro
      // @TODO: change
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.goals.intro.title"
            defaultMessage="Set clear goals"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.goals.intro.text1"
            defaultMessage="Begin your journey by defining clear, actionable goals for your team members. These objectives are crucial for steering your employees' efforts in alignment with your company’s strategic direction."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.goals.intro.text2"
          defaultMessage="To establish a strong goal framework, we'll need some critical details:"
        />
        <OnboardingIntroList
          category="goals"
          items={[
            {
              id: 'name',
              msg: 'goal name',
            },
            {
              id: 'description',
              msg: 'description',
            },
            {
              id: 'target',
              msg: 'target',
            },
            {
              id: 'roadmap',
              msg: 'roadmap (optional)',
            },
            {
              id: 'analytics',
              msg: 'link to analytics (optional)',
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.goals.intro.text3"
          defaultMessage="You can enter this information manually, import it via an integration with your existing task management tools, or upload a file. Remember, the goal name is used as the primary identifier for tracking and assessment purposes."
        />
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        category="goals"
        title="Understand goals hierarchy"
        items={[
          {
            key: 'companyGoals',
            title: 'Company level goals',
            icon: 'Bank',
            text: "Company goals are strategic, focusing on long-term outcomes such as increasing market share, achieving financial targets, launching new products, or expanding into new markets. They are designed to provide a clear vision for the company's future and to align all subsequent goal-setting efforts.",
          },
          {
            key: 'teamGoals',
            title: 'Department/Team goals',
            icon: 'People',
            text: "Department or team goals are more specific objectives that align with the company's overarching goals. These are set by department heads or team leaders and are tailored to the function and expertise of that particular group. These goals ensure that each department or team contributes effectively to the company's broader objectives while working within their specialized areas.",
          },
          {
            key: 'employeeGoals',
            title: 'Employee goals',
            icon: 'Profile',
            text: "Employee goals are set to align with the goals of the team or specific company objectives, and tailored to the individual's role, responsibilities, or professional development. Employee goals provide ownership and help individuals understand how their work contributes to the organisation.",
          },
        ]}
      />
      <OnboardingIntroTips
        category="goals"
        eventSuffix="integrations"
        title="Use integrations"
        text="To enhance the functionality and ease of use, our tool integrates with a variety of popular project management and productivity platforms. This seamless integration facilitates better collaboration and workflow efficiency, allowing for real-time updates and data synchronization across different tools your team may already be using."
        items={[
          {
            key: 'jira',
            title: 'Company level goals',
            icon: 'Jira|image',
            text: "Connect seamlessly with Jira to sync your project tasks and milestones directly with our tool. This integration allows for effortless tracking of team and individual progress against goals, ensuring that project deliverables are aligned with performance metrics. With real-time updates, you can bridge the gap between project management and performance reviews, providing a comprehensive view of your team's achievements",
          },
          {
            key: 'looker',
            title: 'Looker',
            icon: {
              url: 'https://assets.revolut.com/assets/apps/Looker@2x.png',
            },
            text: "Department or team goals are more specific objectives that align with the company's overarching goals. These are set by department heads or team leaders and are tailored to the function and expertise of that particular group. These goals ensure that each department or team contributes effectively to the company's broader objectives while working within their specialized areas.",
          },
        ]}
      />
    </OnboardingIntro>
  )
}
