import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const PayrollIntro = () => {
  return (
    <OnboardingIntro
      // @TODO: change
      videoUrl="/intros/Intro%20-%20Documents.mp4"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        category="payroll"
        title="Handle all payroll information in one place"
        text="From salaries to bonuses, handle everything that impacts payroll in one central place"
        items={[
          {
            key: 'define',
            title: 'Define your payroll cycles',
            icon: 'Document',
            text: 'Define how your payroll works, when employees are paid, cutoff date.',
          },
          {
            key: 'keepTrack',
            title: 'Keep track of all changes in one place',
            icon: 'People',
            text: 'Every time there is a change that is relevant to payroll, you can track this in the payroll cycle screen, making sure that there are no surprises when running payroll.',
          },
          {
            key: 'export',
            title: 'Export your change report',
            icon: 'BalanceSheet',
            text: 'When the cycle is nearing it’s end, download the change report and send it to whoever will take care of payroll.',
          },
        ]}
      />
    </OnboardingIntro>
  )
}
