import { useQueryClient } from 'react-query'

import { apiWithoutHandling } from '@src/api'
import { UseFetchResult } from '@src/interfaces'
import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import {
  OnboardingCheckpointCategory,
  OnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklistV2'
import {
  useGetCompanyPreferences,
  companyPreferencesOnbV2 as companyPreferencesApi,
} from './settings'
import { LocalStorageKeys } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import {
  finalStepsCategories,
  firstStepsCategories,
} from '@src/pages/OnboardingChecklistV2/common/constants'

import { useSubsciptionInfo } from './plans'

type UseGetOnboardingCheckpoints = Pick<
  UseFetchResult<{ results: OnboardingCheckpointInterface[] }>,
  'isError' | 'isLoading'
> & {
  checkpoints?: OnboardingCheckpointInterface[]
}

const checkpointToSectionMap: Record<
  OnboardingCheckpointCategory,
  'hr_apps_to_setup' | 'performance_apps_to_setup' | 'recruitment_apps_to_setup' | null
> = {
  goals: 'performance_apps_to_setup',
  reviews: 'performance_apps_to_setup',

  jobs: 'recruitment_apps_to_setup',
  candidates: 'recruitment_apps_to_setup',
  interviews: 'recruitment_apps_to_setup',

  employeeRecords: 'hr_apps_to_setup',
  timeManagement: 'hr_apps_to_setup',
  documents: 'hr_apps_to_setup',
  payroll: 'hr_apps_to_setup',

  importEmployees: null,
  teams: null,
  roles: null,
  finish: null,
}

export const useGetOnboardingCheckpoints = (): UseGetOnboardingCheckpoints => {
  const { data: onboardingCheckpoints, ...onboardingCheckpointsCtx } = useFetch<{
    results: OnboardingCheckpointInterface[]
  }>(`${API.ONBOARDING_CHECKPOINTS_V2}`, 'v2')
  const { data: companyPreferences, ...companyPreferencesCtx } =
    useGetCompanyPreferences()
  const subscriptionInfo = useSubsciptionInfo()

  const allowContinueOnboarding =
    workspaceLocalStorage.getItem(LocalStorageKeys.ALLOW_CONTINUE_ONBOARDING) === 'True'

  const isLoading =
    onboardingCheckpointsCtx.isLoading ||
    companyPreferencesCtx.isLoading ||
    (allowContinueOnboarding ? false : subscriptionInfo.isLoading)

  const isError =
    onboardingCheckpointsCtx.isError ||
    companyPreferencesCtx.isError ||
    (allowContinueOnboarding ? false : subscriptionInfo.isError)

  if (isLoading || isError || !onboardingCheckpoints || !companyPreferences) {
    return { isLoading, isError }
  }

  const { selected, ...appsToSetup } = {
    ...Object.fromEntries(firstStepsCategories.map(c => [c, true])),
    ...companyPreferences.hr_apps_to_setup,
    ...companyPreferences.performance_apps_to_setup,
    ...companyPreferences.recruitment_apps_to_setup,
    ...Object.fromEntries(finalStepsCategories.map(c => [c, true])),
  }

  const checkpoints = onboardingCheckpoints.results.map<OnboardingCheckpointInterface>(
    checkpoint => {
      const checkIsCheckpointVisible = () => {
        const checkpointEnabled =
          appsToSetup[checkpoint.category as keyof typeof appsToSetup]
        const checkpointSection = checkpointToSectionMap[checkpoint.category]
        const checkpointSectionEnabled = checkpointSection
          ? companyPreferences[checkpointSection].selected
          : checkpointSection === null

        return !!(checkpointEnabled && checkpointSectionEnabled)
      }

      return checkIsCheckpointVisible()
        ? checkpoint
        : {
            ...checkpoint,
            state: { id: 'hidden', name: 'Hidden' },
          }
    },
  )

  return {
    isLoading,
    isError,
    checkpoints,
  }
}

export const useGetOnboardingCheckpointCategory = (
  category: OnboardingCheckpointCategory,
) => {
  const response = useGetOnboardingCheckpoints()

  return {
    ...response,
    data: response.checkpoints?.find(checkpoint => checkpoint.category === category),
  }
}

export const useInvalidateOnboardingCheckpoints = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries(API.ONBOARDING_CHECKPOINTS_V2)
    queryClient.invalidateQueries(API.ONBOARDING_CHECKPOINTS_V2)
  }
}

export const updateOnboardingCheckpointCategoryCurrentStep = (
  category: OnboardingCheckpointCategory,
  current_step: string,
) =>
  apiWithoutHandling.patch(`${API.ONBOARDING_CHECKPOINTS_V2}/${category}`, {
    current_step,
  })

export const completeOnboardingCheckpoint = (category: OnboardingCheckpointCategory) =>
  apiWithoutHandling.post(`${API.ONBOARDING_CHECKPOINTS_V2}/${category}/complete`)

// For development and testing only
export const resetOnboardingCheckpoint = (category: OnboardingCheckpointCategory) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING_CHECKPOINTS_V2}/${category}/reset`,
    undefined,
    undefined,
    'v2',
  )

// For development and testing only
export const enableAllCheckpoints = () =>
  Promise.all([
    companyPreferencesApi.update(
      {
        tools: {},
        other_tools: [],
        other_tools_text: null,
        hr_apps_to_setup: {
          payroll: true,
          documents: true,
          timeManagement: true,
          employeeRecords: true,
          selected: true,
        },
        performance_apps_to_setup: {
          goals: true,
          reviews: true,
          selected: true,
        },
        recruitment_apps_to_setup: {
          jobs: true,
          candidates: true,
          interviews: true,
          selected: true,
        },
        enable_departments: true,
        enable_multi_specialisations: false,
        enable_functions: false,
      },
      { id: '1' },
    ),
  ])
