import React from 'react'
import {
  ProgressWidget,
  VStack,
  Text,
  ActionButton,
  Token,
  Tag,
  Box,
  IconButton,
} from '@revolut/ui-kit'

import {
  checkIfFirstStepsCompleted,
  getCompletedCheckpointsNum,
  getFirstStepsCheckpoints,
  getNextStepsCheckpoints,
} from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useGetAllowedCheckpoints } from '../common/hooks'
import { useDebugNextSteps } from '../common/debug'

type Props = {
  toggleInviteSidebar: () => void
}
export const ProgressBanner = ({ toggleInviteSidebar }: Props) => {
  const checkpoints = useGetAllowedCheckpoints()
  const { debugShowNext, toggleDebugShowNext } = useDebugNextSteps()

  const allFirstStepsCompleted = debugShowNext || checkIfFirstStepsCompleted(checkpoints)
  const checkpointsToTrack = allFirstStepsCompleted
    ? getNextStepsCheckpoints(checkpoints)
    : getFirstStepsCheckpoints(checkpoints)
  const completedNum = getCompletedCheckpointsNum(checkpointsToTrack)

  const title = allFirstStepsCompleted
    ? 'Unlock your core features'
    : 'Setup your account'
  const subTitle = allFirstStepsCompleted
    ? 'Proceed as you prefer, you can also hide modules for now and onboard them later'
    : 'Setup your account'

  return (
    <ProgressWidget>
      <ProgressWidget.Title>
        <VStack>
          <Box mb="s-24">
            <Tag variant="status" useIcon="Time">
              {completedNum}/{checkpointsToTrack.length} completed
            </Tag>
          </Box>
          <Text>{title}</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            {subTitle}
          </Text>
        </VStack>
      </ProgressWidget.Title>
      <ProgressWidget.Description>
        <IconButton
          useIcon={allFirstStepsCompleted ? 'SwitchOn' : 'SwitchOff'}
          color={Token.color.greyTone10}
          onClick={toggleDebugShowNext}
        />
      </ProgressWidget.Description>
      <ProgressWidget.Progress
        value={completedNum ? completedNum / checkpointsToTrack.length : 0.05}
      />
      <ProgressWidget.Actions>
        <ActionButton useIcon="Envelope" onClick={toggleInviteSidebar}>
          Invite admin
        </ActionButton>
      </ProgressWidget.Actions>
    </ProgressWidget>
  )
}
