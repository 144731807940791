import React from 'react'
import { connect } from 'lape'
import {
  Avatar,
  Cell,
  Item,
  Text,
  Token,
  VStack,
  Side,
  Flex,
  chain,
  Subheader,
} from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const InviteSidebarForm = connect(({ onClose }: Props) => {
  return (
    <>
      <VStack space="s-16">
        <LapeNewInput required name="email" label="Email" />
        <LapeRadioSelectInput
          name="access_group"
          label="Access group"
          searchable={false}
          options={[{ label: 'IT-Admin', value: { id: 1, name: 'IT-Admin' } }]}
        />
      </VStack>
      <Flex justifyContent="space-between" mt="s-16" mb="s-8">
        <Subheader>
          <Subheader.Title>{chain('Active invitations', '2')}</Subheader.Title>
        </Subheader>
      </Flex>
      <Cell p={0}>
        <VStack width="100%">
          <Item>
            <Item.Avatar>
              <Avatar label="FM">
                <Avatar.Badge useIcon="Check" bg={Token.color.green} />
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Floyd Miles</Item.Title>
              <Item.Description>
                <Text color={Token.color.green}>Completed</Text>
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Text color={Token.color.greyTone50}>IT-Admin</Text>
            </Item.Side>
          </Item>
          <Item>
            <Item.Avatar>
              <Avatar label="JC" progress={0.75}>
                <Avatar.Badge useIcon="16/StatusClockArrows" bg={Token.color.blue} />
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Jane Cooper</Item.Title>
              <Item.Description>
                <Text color={Token.color.blue}>Pending</Text>
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Text color={Token.color.greyTone50}>IT-Admin</Text>
            </Item.Side>
          </Item>
        </VStack>
      </Cell>
      <Side.Actions>
        <NewSaveButtonWithPopup
          onAfterSubmit={onClose}
          successText="User was successfully invited"
        >
          Invite admin
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
})

type Props = {
  onClose: () => void
}
export const InviteSidebarContent = (props: Props) => (
  <Form disableLocalStorageCaching forceParams={{ id: undefined }}>
    <InviteSidebarForm {...props} />
  </Form>
)
