import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const DocumentsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        category="documents"
        title="Organise and manage all your documents in secure place"
        text="Upload all employee documents, store company policies and generate eSignatures."
        items={[
          {
            key: 'upload',
            title: 'Upload employee documents',
            icon: 'Document',
            text: 'All your employee documents can be stored in one central place',
          },
          {
            key: 'store',
            title: 'Store all company policies in one place',
            icon: 'People',
            text: 'After a policy is created, decide who this policy is assigned to by using filters or groups. For example assign an Annual leave policy to everyone in the UK who is a senior. From there on out, anyone who fits this criteria will be assigned the policy.',
          },
          {
            key: 'store',
            title: 'Request documents & signatures',
            icon: 'People',

            // @TODO: change text
            text: 'After a policy is created, decide who this policy is assigned to by using filters or groups. For example assign an Annual leave policy to everyone in the UK who is a senior. From there on out, anyone who fits this criteria will be assigned the policy.',
          },
        ]}
      />
    </OnboardingIntro>
  )
}
