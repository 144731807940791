// TODO: remove after debug
import React from 'react'
import { IconButton, Token } from '@revolut/ui-kit'

import { useQuery } from '@src/utils/queryParamsHooks'
import { completeOnboardingCheckpoint } from '@src/api/onboardingChecklistV2'
import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklistV2'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'

export const useDebugNextSteps = () => {
  const { query, changeQueryParam, deleteQueryParam } = useQuery()

  const debugShowNext = query.debug === 'true'

  return {
    debugShowNext,
    toggleDebugShowNext: () => {
      if (debugShowNext) {
        deleteQueryParam('debug')
      } else {
        changeQueryParam('debug', 'true')
      }
    },
  }
}

export const CompleteCheckpointButton = () => {
  let category: OnboardingCheckpointCategory | null = null

  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.ANY)) {
    category = 'importEmployees'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.ANY)) {
    category = 'teams'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.ROLES.ANY)) {
    category = 'roles'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.ANY)) {
    category = 'goals'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.ANY)) {
    category = 'reviews'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.ANY)) {
    category = 'jobs'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.ANY)) {
    category = 'candidates'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.ANY)) {
    category = 'interviews'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.ANY)) {
    category = 'employeeRecords'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ANY)) {
    category = 'timeManagement'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.ANY)) {
    category = 'documents'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.ANY)) {
    category = 'payroll'
  }
  if (useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_EMPLOYEES.ANY)) {
    category = 'finish'
  }
  if (!category) {
    return null
  }
  return (
    <IconButton
      useIcon="Check"
      color={Token.color.greyTone20}
      onClick={() => completeOnboardingCheckpoint(category!)}
    />
  )
}
